import React, { useReducer } from 'react'
import progressReducer from  '../reducers/progressReducer'


export const ProgressContext = React.createContext()

const ProgressContextProvider = (props) => {

    let initState = 
    { 
        userProgress: {
            percentage: 0,
            paid: 0
        }, 
        withChipInProgress: {
            percentage: 0,
            paid: 0
        }
    }

    const [progress, dispatch] = useReducer(progressReducer, initState)

    return(
        <ProgressContext.Provider value={{progress, dispatch}}>
            {props.children}
        </ProgressContext.Provider>
    )
}

export default ProgressContextProvider