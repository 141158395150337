import React from "react";
import NavBar from "./NavBar";
import styled from "styled-components/macro";
import logo from "../assets/images/reel-logo.svg";
import device from "../Device";

const Header = () => {
    return (
        <div>
            <NavBar />
            <LogoRow>
                <a href="https://app.joinreel.co/"><Logo src={logo}></Logo></a>
            </LogoRow>
        </div>
    );
};

export default Header;

const LogoRow = styled.div`
    // width: 100%;
    // height: 54px;
    // display: flex;
    // align-items: center;
    // box-shadow: 0 0.5px 0 0 #e0e0e0;

    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    transform: none;
    box-shadow: 0 0.5px 0 0 #e0e0e0;

    & a {
        cursor: pointer;
        margin: 0 auto;

    }

    @media ${device.laptop} {
        position: absolute;
        top: 40px;
        transform: translate(-50%,-50%);
        left: 50%;
        box-shadow: none;
        & a {
            cursor: pointer;
            position : absolute;
            left : 50%
        }
    }
`;

const Logo = styled.img`
    height: 29px;
    display: block;
    margin: 15px auto;
`;
