import React, { useContext } from "react";
import axios from "axios";
import Modal from "react-modal";
import { injectStripe, CardElement } from "react-stripe-elements";
import styled from "styled-components/macro";
import ExitIcon from "../assets/icons/Exit.svg";
import hamburgerIcon from "../assets/icons/hamburger.svg";
import unselectedIcon from "../assets/icons/Unselected.svg";
import selectedIcon from "../assets/icons/Selected.svg";
import userIcon from "../assets/icons/User-Outline.svg";
import emailIcon from "../assets/icons/Mail-Outline.svg";
import lockIcon from "../assets/icons/Lock.svg";
import device from "../Device";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import './Progress.scss';
import { ProgressContext } from "../context/ProgressContext";
import ReactEcharts from "echarts-for-react";

class CCModal extends React.Component {
    constructor() {
        super();
        this.utmParams = "?utm_source=chip%20in&utm_campaign=awareness&utm_content=chipin_modal_startmyownreel_button";
        this.ccInput = React.createRef();
        this.state = {
            modalIsOpen: false,
            gifter: "",
            email: "",
            validEmail: true,
            formCompleted: false,
            processing: false,
            agreement: false,
            recieveReelMail : false,
            agreementError: false,
            success: false,
            error: "none",
            isEnabled: true,
            progress: '',
        };

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    cardName(event) {
        this.setState({ gifter: event.target.value });
    }

    cardEmail(event) {
        this.setState({ email: event.target.value });
    }

    validateEmail(event) {
        "validating email"
        if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(event.target.value) === true) {
            this.setState({ validEmail: true });
        } else if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(event.target.value) === false) {
            this.setState({ validEmail: false });
        }
    }

    openModal(event, value) {
        event.preventDefault();
        this.setState({ modalIsOpen: true });
    }

    agreeToAmount() {
        this.setState({ agreement: !this.state.agreement });
    }
    
    agreeToReelMail(){
        this.setState({ recieveReelMail: !this.state.recieveReelMail });
        
    }

    handleBlur = () => {
        console.log("[blur]");
    };
    handleChange = change => {
        console.log("[change]", change);
        this.setState({ formCompleted: change.complete });
    };
    handleClick = () => {
        console.log("[click]");
    };
    handleFocus = () => {
        console.log("[focus]");
    };
    handleReady = () => {
        console.log("[ready]");
    };

    createOptions = () => {
        return {
            style: {
                base: {
                    fontSize: "16px",
                    color: "#0a0a0a",
                    letterSpacing: "0.025em",
                    fontFamily: "proxima-nova",
                    "::placeholder": {
                        color: "#0a0a0a"
                    },
                    padding: "50px"
                },
                invalid: {
                    color: "#9e2146"
                }
            }
        };
    };

    afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    async  getGoal() {

        const result = await axios.post(
            `${process.env.REACT_APP_API}`,
            {
                data: {
                    userId: this.props.userId,
                    goalId: this.props.goalId
                }
            }
        );
        if (result.data.body.message === "Goal exists") {

            let goal = JSON.parse(result.data.body.goalStatus)

            let paid = JSON.parse(result.data.body.transactionsTotal)

            let itemPrice = goal.target.amount.amount + goal.target.tax.amount

            let percentage = Math.ceil((paid / itemPrice) * 100)

            const totalTargetToPay = itemPrice.toFixed(0);

            this.setState({
                progress: percentage
            })

            // this.getBarWidthAndColor();

        } else {
            console.log(result.data.body.message);
        }
    }

    getBarWidthAndColor() {
        console.log('state', this.state.progress);
        console.log('props', this.props.progress);
        if (this.props.progress && this.state.progress != '') {
            let boostProgess = this.state.progress - this.props.progress.userProgress.percentage;
            let totalProgress = this.state.progress;
            let progressWithoutBoost = this.props.progress.userProgress.percentage;
            return [
                [progressWithoutBoost / 100, '#f39a7c'],
                [totalProgress / 100, '#501639'],
                [1, '#eee8e2'],
            ];

        } else {
            return [
                [this.props.progress.userProgress.percentage / 100, '#f39a7c'],
                [1, '#eee8e2'],
            ]
        }

    }

    closeModal() {
        this.setState({ modalIsOpen: false });
        this.setState({ error: "none" });
        this.setState({ agreement: false });
        this.setState({ recieveReelMail: false });
        this.setState({ agreementError: false });
        if (this.state.success === true) {
            window.location.href = `${process.env.REACT_APP_REDIRECT_LINK}?utm_source=chip%20in&utm_campaign=awareness&utm_content=chipin_modal_xedout`;
        }
    }

 

    getProductImage() {
        if (this.props.userGoal) {
            return this.props.userGoal.product.pictures.default.large
        } else {
            return '';
        }
    }

     capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
      }

    handleSubmit = async e => {
        e.preventDefault();

        if (this.state.agreement === true && this.state.validEmail === true) {
            if (this.state.formCompleted === true  && this.state.gifter.trim().length != 0 && this.state.email.trim().length != 0) {
                this.setState({ isEnabled: false });
                this.setState({ processing: true });
                try {
                    let { token } = await this.props.stripe.createToken({
                        type: "card",
                        address_country: "US",
                        name: this.state.gifter
                    });

                    let chipIn = this.props.value[0] * 100
                    let fee = ((this.props.value[0] * 3) / 100) * 100;

                    const result = await axios.post(
                        `${process.env.REACT_APP_API}`,
                        {
                            data: {
                                token: token,
                                chipIn: chipIn,
                                fee: fee,
                                goalImage: this.props.userGoal.product.pictures.default.large,
                                chipper: this.state.gifter,
                                email: this.state.email,
                                message: this.props.value[1] === "" ? "Here's a little gift for you!" : this.props.value[1],
                                user: {
                                    name: this.props.name,
                                    userId: this.props.userId,
                                    goalId: this.props.goalId
                                }
                            }
                        }
                    );
                    this.setState({ processing: false });
                    this.setState({ isEnabled: true });

                    if (result.data.body.message === "Charged!") {
                        window.dataLayer.push({
                            event: `app.event`,
                            eventCategory: "chip-in",
                            eventType:"success",
                            eventName: "success",
                            eventValue: Math.floor((chipIn + fee) /100),
                        });
                        this.getGoal();
                        this.setState({ success: true });
                    } else {

                        if (result.data.body.message === "Bad token") {
                            this.setState({
                                error: "Invalid card"
                            });
                        } else {
                            this.setState({
                                error:
                                    "Payment processing error please try again"
                            });
                        }
                    }
                } catch (err) {
                    this.setState({ isEnabled: true });
                    throw err;
                }
            } else {
                if(this.state.gifter=== ''){
                    this.setState({gifter : ''})
                }
                this.setState({ error: "Incomplete fields" });
            }
        } else {
            this.setState({ agreementError: true });
        }
    };


    render() {

        //  let { progress, dispatch } = useContext(ProgressContext);
        const getOption = () => ({
            grid: {
                z: 1,
                show: false,
                left: '0%',
                right: '0%',
                top: '0%',
                containLabel: true,
                splitLine: {
                    show: true,
                },
            },
            xAxis: [
                {
                    type: 'category',
                    data: [],
                    axisLine: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    splitArea: {
                        interval: 'auto',
                        show: false,
                    },
                },
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLine: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                },
            ],
            toolbox: {
                show: false,
            },
            series: [
                {
                    type: 'gauge',
                    startAngle: 240,
                    endAngle: -60,
                    radius: '90%',

                    splitNumber: 30,
                    z: 4,
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        length: 24, // separator length
                        lineStyle: {
                            width: 3,
                            color: '#fdfcfa',
                        },
                    },
                    axisLabel: {
                        show: false,
                    },
                    pointer: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            opacity: 0,
                        },
                    },
                    detail: {
                        show: false,
                    },
                    data: [{ value: this.state.progress }],
                },
                {
                    type: 'gauge',
                    startAngle: 240,
                    endAngle: -60,

                    z: 3,
                    min: 0,
                    max: 100,
                    splitNumber: 12,
                    radius: '92%',
                    axisLine: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    pointer: {
                        width: '60px',
                        z: 5,
                        itemStyle: {
                            color: '#d7ccc0',
                        },
                    },
                    itemStyle: {
                        color: '#d7ccc0',
                        fontSize: 12,
                    },
                    detail: {
                        show: false,
                    },
                    data: [{ value: this.state.progress }],
                },
                {
                    type: 'gauge',
                    z: 2,
                    min: 0,
                    max: 100,
                    radius: '90%',
                    startAngle: 240,
                    endAngle: -60,
                    splitNumber: 4,
                    // axisLine: {
                    //     lineStyle: {
                    //         color: [
                    //             [this.state.progress / 100, '#f39a7c'],
                    //             [1, '#eee8e2'],
                    //         ],
                    //         width: 24, // bar width
                    //         opacity: 1,
                    //     },
                    // },
                    axisLine: {
                        lineStyle: {
                            color: this.getBarWidthAndColor(),
                            width: 24, // bar width
                            opacity: 1,
                        },
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                },
            ],
            graphic: [
                {
                    type: 'group',
                    right: 'center',
                    bottom: '73px',
                    z: 7,
                    children: [
                        {
                            type: 'circle',
                            z: 8,
                            shape: {
                                cx: 60,
                                cy: 70,
                                r: 132,
                            },
                            style: {
                                fill: '#fdfcfa',
                                stroke: '#d7ccc0',
                                lineWidth: 2,
                            },
                        },
                    ],
                },
            ],
        })
        let modalContent = () => {
            if (this.state.success === false) {
                return (
                    <StripeForm className="chip-info-wrapper" onSubmit={this.handleSubmit}>
                        <CloseStripeForm
                            type="image"
                            src={hamburgerIcon}
                            onClick={this.closeModal}
                        ></CloseStripeForm>
                        <ModalTitle className="heading">Chip in info</ModalTitle>
                        <ModalText>
                            <p>
                                You are gifting{" "}
                                {this.props.value[2] !== "Friend"
                                    ? `${this.capitalize(this.props.value[2])}`
                                    : `your friend`}{" "}
                                 ${this.props.value[0]} 
                                
                                 {" "}for something they really want!
                            </p>
                        </ModalText>
                        <NameWrapper>
                            <label>FULL NAME</label>
                            {/* <img src={userIcon} alt="User Icon"></img> */}
                            <NameInput
                                name="name"
                                type="text"
                                placeholder="Name and Last Name"
                                required
                                onChange={event => this.cardName(event)}
                            ></NameInput>
                        </NameWrapper>
                        <EmailWrapper>
                        <label>EMAIL ADDRESS</label>
                            {/* <img src={emailIcon} alt="Email Icon"></img> */}
                            <EmailInput
                                name="email"
                                type="text"
                                placeholder="Enter here"
                                required
                                onChange={event => this.cardEmail(event)}
                                onBlur={event => this.validateEmail(event)}
                                valid={this.state.validEmail}
                            ></EmailInput>
                        </EmailWrapper>
                        <CardElement
                            class="credit-card"
                            ref={this.ccInput}
                            onBlur={this.handleBlur}
                            onChange={this.handleChange}
                            onFocus={this.handleFocus}
                            onReady={this.handleReady}
                            {...this.createOptions()}
                            required
                        />
                        <AmountInfo>
                            The total amount charged will be $
                            <strong>
                                {(
                                    this.props.value[0] +
                                    (this.props.value[0] * 3) / 100
                                ).toFixed(2)}{" "}
                            </strong>
                            which includes your ${this.props.value[0]} gift +
                            the 3% card processing fee of $
                            {((this.props.value[0] * 3) / 100).toFixed(2)} .
                        </AmountInfo>
                        <ErrorText error={this.state.error}>
                            {this.state.error}
                        </ErrorText>
                        <AmountAgreement>
                            <Checkbox type="checkbox" name="agreement" id="agreement"></Checkbox>
                            <RoundCheckbox
                                htmlFor="agreement"
                                onClick={e => {
                                    this.agreeToAmount();
                                }}
                            >
                                <img
                                    src={
                                        !this.state.agreement
                                            ? unselectedIcon
                                            : selectedIcon
                                    }
                                    alt="Circle Toggle Icon"
                                />
                            </RoundCheckbox>
                            <SpanText>
                                I confirm the charges and that I have read and agree to Reel’s
                                <a href="https://www.joinreel.co/article/terms-and-conditions/"> Terms of Use </a>&<a href="https://www.joinreel.co/article/privacy-policy/"> Privacy Policy</a>
                            </SpanText>
                            <Required
                                agreementError={this.state.agreementError}
                            >
                                *
                            </Required>
                        </AmountAgreement>
                        <EmailAgreement>
                        <Checkbox type="checkbox" name="emailAgreement" id="emailAgreement"></Checkbox>
                            <RoundCheckbox
                                htmlFor="emailAgreement" 
                                onClick={e => {
                                    this.agreeToReelMail();
                                }}
                            >
                                <img
                                    src={
                                        !this.state.recieveReelMail
                                            ? unselectedIcon
                                            : selectedIcon
                                    }
                                    alt="Circle Toggle Icon"
                                />
                            </RoundCheckbox>
                            <EmailText>
                                 I would like to recieve emails from Reel
                            </EmailText>
                        </EmailAgreement>
                        <SubmitStripe
                            disabled={!this.state.isEnabled}
                            type="submit"
                        >
                            <Loader
                                type="TailSpin"
                                color="#501639"
                                height={20}
                                width={20}
                                visible={this.state.processing ? true : false}
                            />
                            <SubmitText processing={this.state.processing}>
                                CONFIRM
                            </SubmitText>
                        </SubmitStripe>
                        <SecureTag>
                            <img src={lockIcon} alt="Lock Icon"></img>
                            <span>Encrypted with 128-bit SSL. 100% secure.</span>
                        </SecureTag>
                    </StripeForm>
                );
            } else if (this.state.success === true) {
                return (
                    <SuccessModal>
                        <CloseStripeForm
                            type="image"
                            src={hamburgerIcon}
                            onClick={this.closeModal}
                        ></CloseStripeForm>
                        <SuccessModalTitle>Success!</SuccessModalTitle>
                        <SuccessModalText><p>{this.capitalize(this.state.gifter)}, you are a fantastic friend and just helped {this.capitalize(this.props.name)} get this Reel even sooner.</p></SuccessModalText>
                        <section className="chart-container success-popup">
                            <div className="reel" >
                                <div className="img-container">
                                    <img
                                        src={this.getProductImage()}
                                    />
                                </div>
                                <div>
                                    <ReactEcharts option={getOption()} style={{ height: 400 }} />
                                </div>
                                <div className="progress">
                                    <span className="number">{this.state.progress} <small className="small">%</small></span>
                                    <span className="saved-text">saved</span>
                                </div>
                            </div>
                        </section>
                        {/* <ModalImage
                            src={
                                this.props.userGoal !== undefined
                                    ? this.props.userGoal.product.pictures
                                        .default.large
                                    : ""
                            }
                        ></ModalImage> */}
                        <ModalTitle>Done by <span>{this.props.doneBy}</span></ModalTitle>
                        {/* <ModalText>
                            <p>
                                You gifted{" "}
                                <strong>${this.props.value[0]} </strong>
                                towards{" "}
                                {this.props.value[2] !== "Friend"
                                    ? `${this.props.value[2]}`
                                    : `your friend`}
                                {"'s "}
                                Reel.<br></br>Everyone should have a friend like
                                you!
                            </p>
                        </ModalText> */}
                        <ModalText>
                            <p className="about-you">
                                What about you?
                            </p>
                        </ModalText>
                        <a href={process.env.REACT_APP_REDIRECT_LINK + this.utmParams} >
                            <ReelLink onClick={event => {
                               window.dataLayer.push({
                                event: `app.event`,
                                eventCategory: "chip-in",
                                eventType:"Start your own Reel",
                                eventName: "Start your own Reel",
                            });
                            }}>
                                START MY OWN REEL
                            </ReelLink>
                        </a>
                    </SuccessModal>
                );
            }
        };

        return (
            <div className="btn-submit">
                <OpenModal
                    type="submit"
                    onClick={event => {
                        return this.openModal(event, this.props.value);
                    }}
                >
                    CHIP IN
                </OpenModal>
                <Modal
                    ariaHideApp={false}
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    className="Modal"
                    overlayClassName="Overlay"
                    contentLabel="Example Modal"
                >
                    {modalContent()}
                </Modal>
            </div>
        );
    }
}

export default injectStripe(CCModal);

const StyledButton = styled.button`
    // color: white;
    // height: 40px;
    // background-color: #2c0066;
    // display: block;
    // margin: 15px auto 0 auto;
    // border: none;
    // cursor: pointer;
    // font-size: 0.8em;

    color: #000;
    height: 46px;
    background-color: transparent;
    display: block;
    margin: 15px auto 10px auto;
    cursor: pointer;
    font-size: 0.8em;
    width: 85%;
    margin-top: 30px;
    border: 1.2px #000 solid;
    padding: 0;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'Gotham-Book';
    & :focus {
        outline: none;
    }
`;

const OpenModal = styled(StyledButton)`
    width: 100%;
    background-color: #501639;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.92px;
    text-align: center;
    color: #ffffff;
    font-family: 'Gotham-Medium';
`;

const StripeForm = styled.form`
    position: relative;
    padding: 40px 20px;
    text-align: center;
    background: #f7f5f3;
    @media ${device.mobileS} {
        padding: 20px 20px;
    }
`;

const CloseStripeForm = styled.input`
    position: absolute;
    top: 30px;
    right: 30px;
    height: 18px;
    cursor: pointer;

    & :focus {
        outline: none;
    }

    @media ${device.mobileS} {
        right: 15px;
    }
`;

const ModalTitle = styled.h2`
 // font-family: proxima-nova;
    font-family: 'HaboroContrast-NorBoo';
    font-size: 2em;
    text-align: center;
    max-width: 300px;
    margin: 20px auto;

    & span{
        display: inline-block;
        position: relative;
        z-index: 1;

        &::after{
            position: absolute;
            content: '';
            bottom: 5px;
            left: 0;
            width: 100%;
            height: 14px;
            background-color: #f39a7c;
            z-index: -1;
        }
    }

    @media only screen and (max-device-width: 767px) {
        margin: 10 auto;
        font-size: 1.3em;
    }

    @media ${device.mobileS} {
        margin: 10 auto;
        font-size: 1.3em;
    }
`;

const SuccessModalTitle = styled.h2`
    font-family: 'HaboroContrast-NorBoo';
    font-size: 2em;
    text-align: center;
    max-width: 300px;
    margin: 20px auto;

    @media ${device.mobileS} {
        margin: 0 auto;
        font-size: 1.3em;
    }
`;

const ModalText = styled.div`
    margin: 30px 0;

    & p.about-you{
        text-align: center;
    }

    & p {
        font-family: proxima-nova;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        width: 80%;
        margin: 0 auto;
    }
    
    @media only screen and (max-device-width: 767px) {
        margin: 0 auto;
    }

    @media ${device.mobileS} {
        margin: 0 auto;
        font-size: 12px;
    }
`;

const SuccessModalText = styled.div`
    margin: 20px 0;

    & p {
        font-family: proxima-nova;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        width: 80%;
        margin: 0 auto;
    }
    @media ${device.mobileS} {
        margin: 0 auto;
        font-size: 12px;
    }
`;

const ErrorText = styled.div`
    display: block;
    color: #cc0000;
    font-family: proxima-nova;
    font-size: 0.8em;
    visibility: ${props => (props.error === "none" ? "hidden" : "visible")};
    margin: 10px auto;
`;

const NameWrapper = styled.div`
    background-color: transparent;
    height: 40px;
    width: 80%;
    max-width: 350px;
    display: flex;
    margin: 10px auto;
    position: relative;
    padding: 11px 0px;
    border-bottom: 1px solid #0a0a0a;
    flex-direction: column;

    & img {
        position: absolute;
        top: 15px;
        left: 13px;
        height: 14px;
    }

    & label{
        text-align: left;
        margin-bottom: 6px;
        font-family: 'Gotham-Book';
        font-weight: 600;
        font-size: 12px;
    }
    @media ${device.mobileS} {
        margin: 5px auto;
    }
`;

const NameInput = styled.input`
    padding-left: 0px;
    width: 100%;
    background-color: transparent;
    border: none;
    color: #0a0a0a;
    font-size: 16px;
    font-family: 'Gotham-Book';
    opacity: 0.6;
    font-weight: normal;
    & :focus {
        outline: none;
    }
    
`;

const EmailWrapper = styled.div`
    background-color: transparent;
    height: 40px;
    width: 80%;
    max-width: 350px;
    display: flex;
    margin: 10px auto;
    position: relative;
    padding: 11px 0px;
    border-bottom: 1px solid #0a0a0a;
    flex-direction: column;

    & img {
        position: absolute;
        top: 15px;
        left: 12px;
        height: 13px;
    }

    & label{
        text-align: left;
        margin-bottom: 6px;
        font-family: 'Gotham-Book';
        font-weight: 600;
        font-size: 12px;
    }

    @media ${device.mobileS} {
        margin: 5px auto;
    }
`;

const EmailInput = styled.input`
    padding-left: 0px;
    width: 100%;
    background-color: transparent;
    border: none;
    color: ${props => (props.valid === true ? '#0a0a0a' : '#cc0000')};
    font-size: 16px;
    font-family: 'Gotham-Book';
    opacity: 0.6;
    font-weight: normal;
    & :focus {
        outline: none;
    }
`;

const AmountInfo = styled.span`
    display: block;
    font-family: 'Gotham-Book';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    margin: 0px auto;
    width: 85%;
    margin-top: 20px;
    text-align: left;
    font-weight: 600;
    letter-spacing: 0.6px;

    @media ${device.mobileS} {
        font-size: 13px;
        margin-top: 20px;
    }
    
`;

const AmountAgreement = styled.div`
    display: flex;
    align-items: start;
    line-height: 20px;
    justify-content: center;
    width: 85%;
    position: relative;
    right: 0px;
    margin: 20px auto 0px auto;
    text-align: left;
    align-items: flex-start;
`;

const EmailAgreement = styled.div`
    display: flex;
    align-items: start;
    line-height: 20px;
    justify-content: center;
    width: 85%;
    position: relative;
    right: 0px;
    margin: 20px auto 0px auto;
    text-align: left;
    align-items: flex-start;
`;

const Required = styled.span`
    color: #cc0000;
    visibility: ${props => (props.agreementError ? "visible" : "hidden")};
    position: relative;
    top: 2px;
    left: 3px;
`;

const Checkbox = styled.input`
    display: none;
    cursor: pointer;
`;

const RoundCheckbox = styled.label`
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    right: 0;
    top: 0px;
    flex: 1;

    & img {
        height: 20px;
        filter: brightness(0%);
    }
`;

const SpanText = styled.span`
    // font-family: proxima-nova;
    margin: 0 auto;
    line-height: 20px;
    font-family: 'Gotham-Book';
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.13px;
    color: #000;
    flex: 12;
    margin-left: 10px;
    font-weight: 600;

    & a {
        color: #2c0066;
    }

    @media ${device.mobileS} {
        font-size: 10px;
        line-height: 14px;
    }

`;

const EmailText = styled.span`
    // font-family: proxima-nova;
    margin: 0 auto;
    line-height: 20px;
    font-family: 'Gotham-Book';
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.13px;
    color: #000;
    flex: 12;
    margin-left: 10px;
    font-weight: 600;

    & a {
        color: #2c0066;
    }

    @media ${device.mobileS} {
        font-size: 10px;
        line-height: 14px;
    }

`;

const SubmitStripe = styled(StyledButton)`
    width: 85%;

    @media ${device.mobileS} {
        margin: 10px auto;
    }
`;

const SubmitText = styled.div`
    display: ${props => (props.processing ? "none" : "block")};
`;

const SuccessModal = styled.div`
    position: relative;
    padding:  20px;
    text-align: center;
    // min-height: 482px;

    @media ${device.mobileS} {
        padding: 30px 20px;
    }

    & a {
        text-decoration: none;
    }
`;

const ModalImage = styled.img`
    max-width: 75%;
    margin: 50px auto;
    max-height: 175px;
`;

const ReelLink = styled(StyledButton)`
    width: 100%;
    text-decoration: none;
    letter-spacing: 0.69px;
    border-color: #501639;
    color: #fff;
    background-color: #501639;
`;

const SecureTag = styled.div`
    margin-top: 5px;
    & img {
        height: 11px;
        position: relative;
        top: 1px;
        right: 5px;
    }
    & span {
        font-size 10px;
        line-height: 10px;
        font-family: proxima-nova;
    }
`