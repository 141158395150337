import React, { useState, useContext, useEffect } from "react";
import Slider from 'react-input-slider';
import { Elements } from "react-stripe-elements";
import styled from "styled-components/macro";
import plusIcon from "../assets/icons/Plus.svg";
import minusIcon from "../assets/icons/Minus.svg";
import unselectedIcon from "../assets/icons/Unselected.svg";
import selectedIcon from "../assets/icons/Selected.svg";
import messageIcon from "../assets/icons/Message-Outline.svg";
import CCModal from "./CardInformationForm";
import { PaymentContext } from "../context/PaymentContext";
import { ProgressContext } from "../context/ProgressContext";
const fonts = [{ cssSrc: "https://use.typekit.net/zel0tkp.css?" }];


const PaymentForm = ({ userName, itemPrice, goalOwner, userGoal, userId, goalId }) => {
	let { payment, dispatch } = useContext(PaymentContext);
	let { progress } = useContext(ProgressContext);

	let MONTH_NAMES = [
		{
			long: "January",
			short: "Jan"
		},
		{
			long: "February",
			short: "Feb"
		},
		{
			long: "March",
			short: "Mar"
		},
		{
			long: "April",
			short: "Apr"
		},
		{
			long: "May",
			short: "May"
		},
		{
			long: "June",
			short: "Jun"
		},
		{
			long: "July",
			short: "Jul"
		},
		{
			long: "August",
			short: "Aug"
		},
		{
			long: "September",
			short: "Sep"
		},
		{
			long: "October",
			short: "Oct"
		},
		{
			long: "November",
			short: "Nov"
		},
		{
			long: "December",
			short: "Dec"
		}
	];
	let [span, showSpan] = useState(true);
	let [input, showInput] = useState(false);
	let [message, saveMessage] = useState("");
	const [state, setState] = useState({ x: 5 });

	function getFullBalance() {
		let balance = itemPrice - progress.userProgress.paid;
		return Math.round(balance / 100);

	}

	function getStrategyEndDateMsg(reel, useCharacter = true, strategy = null) {
		if (reel) {
			const date = new Date();
			date.setDate(date.getDate() + getDuration(reel, strategy) - 1 + 10);
			let getYear = getDisplayYear(date);
			let dateMsg = `${getDisplayMonth(date)} ${date.getDate()}`
			if (useCharacter) {
				return `${dateMsg} ${getYear ? (', ' + getYear) : '!'}`;
			}
			return `${dateMsg} ${getYear ? (', ' + getYear) : ''}`;
		}
	}

	function getDuration(reel, strategy) {
		strategy = strategy || reel.strategy;
		const duration = Math.ceil(reel.product.price.amount / strategy.amount);
		return duration * (strategy.frequency === 'day' ? 1 : 7);
	}

	function getDisplayYear(date) {
		let currentDate = new Date();
		return (currentDate.getFullYear() != date.getFullYear()) ? `${date.getFullYear()}` : null;
	}

	function getDisplayMonth(date) {
		return `${MONTH_NAMES[date.getMonth()] ? MONTH_NAMES[date.getMonth()].short : ''}`;
	}

	function getProductName() {
		if (userGoal) {
			return `${userGoal.product.brand.name} ${userGoal.product.name}`
		} else {
			return '';
		}
	}


	useEffect(() => {
		dispatch({ type: "UPDATE_FULL_AMOUNT", fullAmount: getFullBalance() });

	}, [payment.fullBalance]);

	let minusOne = e => {
		e.preventDefault();
		dispatch({ type: "UPDATE_FULL_BALANCE", boolean: false });
		if (payment.amount <= 5) {
			dispatch({ type: "UPDATE_AMOUNT", amount: 5 });
		} else
			dispatch({
				type: "UPDATE_AMOUNT",
				amount: payment.amount !== 0 ? payment.amount - 1 : 0
			});
	};

	const updatePayment = (slideValue) => {
		//	e.preventDefault();
		dispatch({ type: "UPDATE_FULL_BALANCE", boolean: false });
		if (slideValue <= 5) {
			dispatch({ type: "UPDATE_AMOUNT", amount: 5 });
		} else
			dispatch({
				type: "UPDATE_AMOUNT",
				amount: slideValue
			});
	}

	let plusOne = e => {
		e.preventDefault();
		dispatch({ type: "UPDATE_FULL_BALANCE", boolean: false });
		if (payment.amount >= getFullBalance()) {
			dispatch({ type: "UPDATE_AMOUNT", amount: getFullBalance() });
		} else dispatch({ type: "UPDATE_AMOUNT", amount: payment.amount + 1 });
	};

	let saveInput = e => {
		e.preventDefault();
		dispatch({ type: "UPDATE_FULL_BALANCE", boolean: false });
		if (e.target.value <= 5) {
			dispatch({ type: "UPDATE_AMOUNT", amount: 5 });
		}
		else if (e.target.value >= getFullBalance()) {
			dispatch({ type: "UPDATE_AMOUNT", amount: getFullBalance() });

		}
		else
			dispatch({
				type: "UPDATE_AMOUNT",
				amount: e.target.value.length !== 0 ? parseInt(e.target.value) : 0
			});
	};

	const giftMessage = event => {
		saveMessage((message = event.target.value));
	};

	return (
		<FormContainer autoComplete="false" className="form-wrapper">
			<p className="desktaop-view-box">{goalOwner}  is saving up to buy a <strong>{getProductName()}</strong>. Be a good friend by chipping in towards what {userName} really wants.</p>
			<div className="date-by">Done by <span>{getStrategyEndDateMsg(userGoal, false)}</span></div>

			<SpanText className="want-mobile">I WANT TO CHIP IN</SpanText>
			<AmountContainer className="slider-wrapper">
				<IncrementButton className="decrease-btn"
					alt="Decrease Button"
					src={minusIcon}
					onClick={e => {
						minusOne(e);
					}}
				/>
				{!input && (
					<Amount className="slider-amount"
						id="payment"
						onClick={e => {
							showInput(!input);
							showSpan(!span);
						}}
					>
						$
						{payment.fullBalance === true
							? payment.fullAmount
							: payment.amount}
					</Amount>
				)}
				{input && (
					<AmountInput
						id="amountInput"
						defaultValue={payment.amount}
						autoFocus
						onBlur={e => {
							showInput(!input);
							showSpan(!span);
						}}
						onChange={e => {
							saveInput(e);
						}}
						min="5"
						max={getFullBalance()}
						maxLength="6"
						onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength) }}
						autocomplete="off"
					></AmountInput>
				)}
				<IncrementButton className="increase-btn"
					alt="Increase Button"
					src={plusIcon}
					onClick={e => {
						plusOne(e);
					}}
				/>
				<Slider className="slider-box"
					xmax={getFullBalance()}
					x={state.x}
					onChange={({ x }) => { setState(state => ({ ...state, x })); updatePayment(x) }}
					styles={{
						track: {
							backgroundColor: '#979797'
						},
						active: {
							backgroundColor: '#f5997b'
						},
						thumb: {
							width: 10,
							height: 23
						}
					}}
				/>
			</AmountContainer>
			<TextContainer className="radio-button" >
				<Checkbox id="fullBalance" type="checkbox"></Checkbox>
				<RoundCheckbox
					htmlFor="fullBalance"
					onClick={() => {
						if(!payment.fullBalance){
                            setState({ x: getFullBalance() })
						}else{
							setState({ x: 5 })
						}
						
						dispatch({
							type: "UPDATE_FULL_BALANCE",
							boolean: !payment.fullBalance
						});
					}}
				>
					<img
						src={
							!payment.fullBalance ? unselectedIcon : selectedIcon
						}
						alt="Full Balance Toggle Icon"
					/>
				</RoundCheckbox>
				<LabelText className="radio-btn" htmlFor="fullBalance" onClick={() => {
					dispatch({
						type: "UPDATE_FULL_BALANCE",
						boolean: !payment.fullBalance
					});
				}}>I want to chip in the full balance</LabelText>
			</TextContainer>
			<MessageWrapper className="message-wrapper">
				{/* <img src={messageIcon} alt="Message Icon"></img> */}
				<div className="text-area-heading">LEAVE A MESSAGE FOR {userName.toUpperCase()}</div>
				<MessageInput
					name="gift-message"
					rows="5"
					cols="30"
					maxLength="200"
					placeholder={`Type here`}
					onChange={event => giftMessage(event)}
				></MessageInput>
			</MessageWrapper>
			<Elements fonts={fonts}>
				<CCModal
					value={[
						payment.fullBalance
							? payment.fullAmount
							: payment.amount,
						message,
						goalOwner
					]}
					doneBy={getStrategyEndDateMsg(userGoal, false)}
					userGoal={userGoal}
					progress={progress}
					userId={userId}
					goalId={goalId}
					name={userName}
				/>
			</Elements>
		</FormContainer>
	);
};

export default PaymentForm;


const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
	margin-top: 15px;
	width: 418px;
	margin-top:100px;
`;



const SpanText = styled.span`
    font-size: 19.2px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    font-family: 'Gotham-Medium';
    margin: 15px 0;
	line-height: 20px;
`;

const LabelText = styled.label`
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    font-family: 'Gotham-Book';
    margin: 15px 0;
	line-height: 20px;
	cursor: pointer;

`;

const AmountContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 15px 0;
`;

const Amount = styled.span`
    font-size: 42px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
    text-align: center;
    color: #000000;
    width: auto;
	text-align: center;
	cursor: pointer;
	margin-right:20px;
	font-family: 'HaboroContrast-NorBoo';

`;

const AmountInput = styled.input.attrs(() => ({
	type: "number",
}))`
    font-family: Gotham-Book;
    font-size: 1.3em;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
    text-align: center;
    color: #000000;
    width: 80px;
    text-align: center;
	border: none;
	padding: 0;


    & :focus {
        outline: none;
	}

	 ::-webkit-inner-spin-button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}
	& ::-webkit-outer-spin-button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}
`;

const IncrementButton = styled.input.attrs(() => ({
	type: "image"
}))`
    margin: 0 15px;
    height: 35px;
	transition: transform 0.5s linear;
	cursor: pointer;


    & :focus {
		outline: none;
	}

	& :hover {
		transform: scale(1.10);
	}

	& :active {
		transform: scale(1);
	}

`;
const Checkbox = styled.input`
	display: none;
	cursor: pointer;

`;

const RoundCheckbox = styled.label`
    position: relative;
    right: 10px;
    display: flex;
    align-items: center;
	cursor: pointer;

    & img {
        height: 20px;
	}
`;

const TextContainer = styled.div`
    display: flex;
    align-items: center;
    line-height: 20px;
`;

const MessageWrapper = styled.div`
    background-color:transparent;
    height: 50px;
    width: 100%;
    max-width: 350px;
    display: block;
    margin: 20px 0;
    position: relative;
    padding: 11px;
	border-bottom: 1px solid #979797;
	padding-left: 0;

    & img {
        position: absolute;
        top: 15px;
        left: 13px;
        height: 14px;
	}
	
	@media only screen and (max-width: 768px){
		max-width: inherit;
	}
`;

const MessageInput = styled.textarea`
    background-color: transparent;
    border: none;
    resize: none;
    padding-left: 0;
    width: 100%;
    font-size: 12px;
	color: #0a0a0a0;
	font-family: proxima-nova;


	& ::placeholder {
		color:; #707070
		font-size: 20px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.37;
		letter-spacing: -0.38px;
		font-family:'Gotham-Book';
    }

    & :focus {
        outline: none;
    }
`;
