import React from "react";
import styled from "styled-components/macro";
import Header from "./components/Header";
import Goal from "./components/Goal";
// import { useAuth0 } from "./react-auth0-spa";
import PaymentContextProvider from "./context/PaymentContext";
import ProgressContextProvider from "./context/ProgressContext";

function App () {
	const script = document.createElement('script');
	script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
	new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
	j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
	'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
	})(window,document,'script','dataLayer','${process.env.REACT_APP_GA_TRACKING_CODE}');`;
	script.async =true;
	document.head.appendChild(script);

	window.dataLayer.push({
		event: `app.event`,
		eventCategory: "chip-in",
		eventType:"open",
		eventName: "open",
	});
	// const { isAuthenticated, loginWithRedirect, logout, loading } = useAuth0();
	// const params = { login: "share@joinreel.com" };

	// const { isAuthenticated, loading } = useAuth0();
	// if ( loading ) {
	// 	return (
	// 		<div>Loading...</div>
	// 	)
	// }
	return (
		<AppContainer>
			<Header />
			<PaymentContextProvider>
				<ProgressContextProvider>
					<Goal />
				</ProgressContextProvider>
			</PaymentContextProvider>
			{/* {!isAuthenticated ? loginWithRedirect( {} ) : <Goal />} */}
			{/* {!isAuthenticated ? <Goal /> : loginWithRedirect( {} )} */}

		</AppContainer>
	);
}

export default App;

const AppContainer = styled.div`
    width: 100%;
    font-family: Gotham-Book;
`;