import React from "react";
import styled from "styled-components/macro";
import device from "../Device";

const NavBar = () => {
    return (
        <NavBarContainer>
            <NavBarTop>
                <NavLeft>
                    <NavLink
                        href="https://www.joinreel.co/article/faqs/"
                        target="_blank"
                    >
                        FAQ
                    </NavLink>
                    <NavLink
                        href="https://www.joinreel.co/article/how-it-works/"
                        target="_blank"
                    >
                        HOW REEL WORKS
                    </NavLink>
                </NavLeft>
                <NavRight>
                    <NavLink
                        href="https://app.joinreel.co/support"
                        target="_blank"
                    >
                        SUPPORT
                    </NavLink>
                    <NavLink
                        href="https://app.joinreel.co/login"
                        target="_blank"
                    >
                        SIGN IN
                    </NavLink>
                </NavRight>
            </NavBarTop>
            <NavBarMobile>
                <NavLinkMobile
                    href="https://www.joinreel.co/article/faqs/"
                    target="_blank"
                >
                    FAQ
                </NavLinkMobile>
                <NavLinkMobileMiddle
                    href="https://www.joinreel.co/article/how-it-works/"
                    target="_blank"
                >
                    HOW REEL WORKS
                </NavLinkMobileMiddle>
                <NavLinkMobile
                    href="https://app.joinreel.co/login"
                    target="_blank"
                >
                    SIGN IN
                </NavLinkMobile>
            </NavBarMobile>
        </NavBarContainer>
    );
};

export default NavBar;

const NavBarContainer = styled.div`
    // background-color: #f5f5f5;
    // width: 100%;
    // height: 28px;
    // border-top: 4px solid #2c0066;

    background-color: #f7f5f3;
    width: 100%;
    height: auto;
    box-shadow: none;
    margin-top: 20px;

    @media ${device.laptop} {
        box-shadow: 0 0.5px 0 0 #e0e0e0;
        height: 80px;
        margin-top: auto;
    }
`;
const NavBarTop = styled.div`
    display: none;
    width: 85%;
    height: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    @media ${device.laptop} {
        display: flex;
    }
`;

const NavLeft = styled.div`
    display: flex;
    & > a {
        margin-right: 50px;
        position: relative;
        top: 1px;
    }
`;

const NavRight = styled.div`
    display: flex;
    & > a {
        margin-left: 50px;
        position: relative;
        top: 1px;
    }
`;

const NavBarMobile = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
        display: none;
    }
`;

const NavLink = styled.a`
    font-size: 16px;
    font-family: Gotham-Medium;
    text-decoration: none;
    font-weight: 500;
    font-stretch: normal;
    color: #0a0a0a;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
    letter-spacing: 0.2px;
`;

const NavLinkMobile = styled.a`
    font-size: 12px;
    font-family: Gotham-Medium;
    text-decoration: none;
    letter-spacing: 0.2px;
    font-weight: 500;
    font-stretch: normal;
    color: #0a0a0a;
    -webkit-font-smoothing: antialiased;
    margin: 0 12px;
    cursor: pointer;

    @media ${device.mobileL} {
        font-size: 16px;
    }

`;

const NavLinkMobileMiddle = styled.a`
    font-size: 12px;
    font-family: Gotham-Medium;
    text-decoration: none;
    letter-spacing: 0.2px;
    font-weight: 500;
    font-stretch: normal;
    color: #0a0a0a;
    margin: 0 12px;
    -webkit-font-smoothing: antialiased;
    position: relative;
    left: 10px;
    cursor: pointer;

    @media ${device.mobileL} {
        font-size: 16px;
    }
`;
