import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
// import * as authConfig from './auth_config.json'

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState( {}, document.title, window.location.pathname );

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext( Auth0Context )

export const Auth0Provider = ( {
	children,
	onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
	...initOptions
} ) => {
	const [isAuthenticated, setIsAuthenticated] = useState();
	const [user, setUser] = useState();
	const [auth0Client, setAuth0] = useState();
	const [loading, setLoading] = useState( true );
	const [popupOpen, setPopupOpen] = useState( false );

	useEffect( () => {
		const initAuth0 = async () => {
			const auth0FromHook = await createAuth0Client( initOptions );
			setAuth0( auth0FromHook );

			if ( window.location.search.includes( "code=" ) ) {
				const { appState } = await auth0FromHook.handleRedirectCallback();
				onRedirectCallback( appState );
				// console.log( { appState } )
			}

			const isAuthenticated = await auth0FromHook.isAuthenticated();
			// console.log( 'isAuthenticated: ', auth0FromHook.isAuthenticated )

			setIsAuthenticated( isAuthenticated );

			if ( isAuthenticated ) {
				const user = await auth0FromHook.getUser();
				// console.log( 'auth0FromHook: ', auth0FromHook )
				// console.log( 'getUser: ', auth0FromHook.getUser() )
				// console.log( 'user: ', user )
				setUser( user );
			}

			setLoading( false );
		}
		initAuth0();
		// eslint-disable-next-line
	}, [] );

	const loginWithPopup = async ( params = {} ) => {
		setPopupOpen( true );
		try {
			await auth0Client.loginWithPopup( params );
			// console.log( 'params: ', params );
			// console.log( 'auth0Client.loginPopup', auth0Client.loginWithPopup )
		} catch ( error ) {
			// console.error( error );
		} finally {
			setPopupOpen( false );
		}
		const user = await auth0Client.getUser();
		setUser( user );
		setIsAuthenticated( true );
	};

	const handleRedirectCallback = async () => {
		setLoading( true );
		await auth0Client.handleRedirectCallback();
		const user = await auth0Client.getUser();
		setUser( user );
		setIsAuthenticated( true );
		setUser( user )
	}

	return (
		<Auth0Context.Provider
			value={{
				isAuthenticated,
				user,
				loading,
				popupOpen,
				loginWithPopup,
				handleRedirectCallback,
				getIdTokenClaims: ( ...p ) => auth0Client.getIdTokenClaims( ...p ),
				loginWithRedirect: ( ...p ) => auth0Client.loginWithRedirect( ...p ),
				getTokenSilently: ( ...p ) => auth0Client.getTokenSilently( ...p ),
				getTokenWithPopup: ( ...p ) => auth0Client.getTokenWithPopup( ...p ),
				logout: ( ...p ) => auth0Client.logout( ...p )
			}}>
			{children}
		</Auth0Context.Provider>
	)
}