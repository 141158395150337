import React, { useEffect, useContext } from "react";
import styled from "styled-components/macro";
import device from "../Device";
import { ProgressContext } from "../context/ProgressContext";
import ReactEcharts from "echarts-for-react";
import './Progress.scss'

const Progress = ({ loading, payment, itemPrice, userId, goalId,userGoal,goalOwner, transactionsTotal }) => {
  let { progress, dispatch } = useContext(ProgressContext);

  function userPercentage(transactionsTotal) {
    const totalTargetToPay = itemPrice.toFixed(0);

    let percentage = Math.ceil((transactionsTotal / totalTargetToPay) * 100);
    dispatch({ type: "USER_PERCENTAGE", percentage: percentage });
    dispatch({ type: "USER_PAID", paid: transactionsTotal });
  }

  function getProductImage(){
    if(userGoal){
      return userGoal.product.pictures.default.large 
    }else{
      return '';
    }
}
function getProductName(){
  if(userGoal){
    return `${userGoal.product.brand.name} ${userGoal.product.name}`
  }else{
    return '';
  }
}

  function chipInPercentage(transactionsTotal) {
    if (itemPrice !== 0) {

      const totalTargetToPay = itemPrice.toFixed(0);

      let amount = payment.fullBalance
        ? payment.fullAmount
        : payment.amount;
      const totalWithChipIn = transactionsTotal + amount * 100;

      let percentage = Math.ceil(
        (totalWithChipIn / totalTargetToPay) * 100
      );
      dispatch({
        type: "WITH_CHIP_IN_PERCENTAGE",
        percentage: percentage
      });
      dispatch({ type: "WITH_CHIP_IN_PAID", paid: totalWithChipIn });
    } else {
      dispatch({ type: "WITH_CHIP_IN_PERCENTAGE", percentage: 0.00001 });
    }
  }

  useEffect(() => {
    userPercentage(transactionsTotal);
    chipInPercentage(transactionsTotal);
  }, [payment, itemPrice, transactionsTotal]);

  const getOption = () => ({
    grid: {
      z: 1,
      show: false,
      left: '0%',
      right: '0%',
      top: '0%',
      containLabel: true,
      splitLine: {
        show: true,
      },
    },
    xAxis: [
      {
        type: 'category',
        data: [],
        axisLine: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        splitArea: {
          interval: 'auto',
          show: false,
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLine: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
    ],
    toolbox: {
      show: false,
    },
    series: [
      {
        type: 'gauge',
        startAngle: 240,
        endAngle: -60,
        radius: '90%',

        splitNumber: 30,
        z: 4,
        axisTick: {
          show: false,
        },
        splitLine: {
          length: 24, // separator length
          lineStyle: {
            width: 3,
            color: '#fdfcfa',
          },
        },
        axisLabel: {
          show: false,
        },
        pointer: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            opacity: 0,
          },
        },
        detail: {
          show: false,
        },
        data: [{ value: progress.userProgress.percentage }],
      },
      {
        type: 'gauge',
        startAngle: 240,
        endAngle: -60,

        z: 3,
        min: 0,
        max: 100,
        splitNumber: 12,
        radius: '92%',
        axisLine: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        pointer: {
          width: '60px',
          z: 5,
          itemStyle: {
            color: '#d7ccc0',
          },
        },
        itemStyle: {
          color: '#d7ccc0',
          fontSize: 12,
        },
        detail: {
          show: false,
        },
        data: [{ value: progress.userProgress.percentage }],
      },
      {
        type: 'gauge',
        z: 2,
        min: 0,
        max: 100,
        radius: '90%',
        startAngle: 240,
        endAngle: -60,
        splitNumber: 4,
        axisLine: {
          lineStyle: {
            color: [
              [progress.userProgress.percentage / 100, '#f39a7c'],
              [1, '#eee8e2'],
            ],
            width: 24, // bar width
            opacity: 1,
          },
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
    ],
    graphic: [
      {
        type: 'group',
        right: 'center',
        bottom: '73px',
        z: 7,
        children: [
          {
            type: 'circle',
            z: 8,
            shape: {
              cx: 60,
              cy: 70,
              r: 132,
            },
            style: {
              fill: '#fdfcfa',
              stroke: '#d7ccc0',
              lineWidth: 2,
            },
          },
        ],
      },
    ],
  }
  );


  return (
    // <ProgressContainer>
    //     {!loading && <ProgressText>
    //         {progress.userProgress.percentage}% Saved
    //     </ProgressText> }
    //     <Bars>
    //         <ProgressWrapper progress={progress}>
    //             <ProgressBar></ProgressBar>
    //             <ProgressTriangle></ProgressTriangle>
    //         </ProgressWrapper>
    //         <ChipInWrapper progress={progress}>
    //             <ChipInBar></ChipInBar>
    //             <ChipInTriangle></ChipInTriangle>
    //         </ChipInWrapper>
    //     </Bars>
    // </ProgressContainer>

    // <div className="App">
    //      <p>{progress.userProgress.percentage}</p>

    //     <ReactEcharts option={getOption()} style={{ height: 300 }} />
    //   </div>
    <div className="main-wrapper">      
    <h2>{goalOwner}’s Reel</h2>
    <p className="mobile-view-desc">{goalOwner}  is saving up to buy a <strong>{getProductName()}</strong>. Be a good friend by chipping in towards what {goalOwner} really wants.</p>
      <section className="chart-container">
        <div className="reel" >
          <div className="img-container">
            <img
              src={getProductImage()}
            />
          </div>
          <div>
          <ReactEcharts option={getOption()} style={{ height: 400 }} />
          </div>
          <div className="progress">
            <span className="number">{progress.userProgress.percentage} <small className="small">%</small></span>
            <span className="saved-text">saved</span>
          </div>
        </div>
      </section>
    </div>



  );
};

export default Progress;



// const ProgressContainer = styled.div`
//     width: 100%;
//     height: 50px;
//     background-color: #fbfbfb;
//     position: relative;
//     margin-top: 30px;
//     overflow: hidden;

//     @media ${device.tablet} {
//         max-width: 625px;
//         margin-left: auto;
//         margin-right: auto;
//     }
// `;

// const Bars = styled.div`
//     overflow: hidden;
//     position: relative;
//     width: calc(100% + 23px);
// `;

// const ProgressWrapper = styled.div`
//     display: flex;
//     width: ${props => props.loading ? "0" : props.progress.userProgress.percentage}%;
//     transition: all 0.5s linear;

// `;

// const ProgressTriangle = styled.div`
//     width: 0px;
//     height: 0px;
//     border-top: 25px solid transparent;
//     border-bottom: 25px solid transparent;
//     border-left: 25px solid #1decf6;
//     z-index: 1;
// `;

// const ChipInWrapper = styled.div`
//     display: flex;
//     width: ${props => props.loading ? "0" : props.progress.withChipInProgress.percentage}%;
//     position: relative;
//     top: -50px;
//     transition: all 0.5s linear;

// `;

// const ChipInTriangle = styled.div`
//     width: 0px;
//     height: 0px;
//     border-top: 25px solid transparent;
//     border-bottom: 25px solid transparent;
//     border-left: 25px solid rgba(29, 236, 246, 0.39);
//     z-index: 0;

// `;

// const ProgressBar = styled.div`
//         width 100%;
//         height: 50px;
//         background-color: #1decf6;
//         z-index: 1;
//         transition: all 0.5s linear;

//     `;

//     const ChipInBar = styled.div`
//         height: 50px;
//         background-color: rgba(29, 236, 246, 0.39);
//         z-index: 0;
//         width: 100%;
//     `;

//     const ProgressText = styled.h2`
//         position: absolute;
//         left: 50%;
//         top: 50%;
//         transform: translate(-50%, -50%);
//         font-family: Gotham-Book;
//         font-size: 1.1em;
//         line-height: 2;
//         text-align: center;
//         margin: 0;
//         z-index: 100;
//         font-weight: 100;
//         display: ${props => props.loading ? "none" : "block"};
//     `;
