const paymentReducer = (state, action) => {
    switch(action.type) {
        case 'UPDATE_AMOUNT':
            return { ...state, amount: action.amount}
        case 'UPDATE_FULL_AMOUNT':
            return { ...state, fullAmount: action.fullAmount}
        case 'UPDATE_FULL_BALANCE':
            return { ...state, fullBalance: action.boolean}
        
        default:
            return state
    }
}

export default paymentReducer