import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Progress from "./Progress";
import styled from "styled-components/macro";
import device from "../Device";
import PaymentForm from "./PaymentForm";
import { PaymentContext } from "../context/PaymentContext";
import { ProgressContext } from "../context/ProgressContext";
import ErrorIcon from "../assets/images/oops.png";


const Goal = () => {
    let uri = window.location.href;

    const params = new URLSearchParams(uri);
    const firstName = params.get("name");
    const userId = params.get("user");
    const goalId = params.get("goal");

    let { payment } = useContext(PaymentContext);
    let { progress } = useContext(ProgressContext);

    const [loading, setLoading] = useState(false);
    const [userGoal, setUserGoal] = useState();
    const [transactionsTotal, setTransactionsTotal] = useState();
    const [goalStatus, setGoalStatus] = useState("");
    const [goalExists, setGoalExists] = useState(true);
    const [canChipIn, setCanChipIn] = useState(true);


    let itemPrice =
        userGoal !== undefined ?
            userGoal.target.amount.amount + userGoal.target.tax.amount : 1;

    let goalOwner = firstName !== "name" && firstName !== "" ? firstName : "Friend";
    const utmParams = "?utm_source=chip%20in&utm_campaign=awareness&utm_content=chipin_modal_startmyownreel_button";



    useEffect(() => {
        setLoading(true);
        async function getGoal() {
            const result = await axios.post(
                `${process.env.REACT_APP_API}`,
                {
                    data: {
                        userId: userId,
                        goalId: goalId
                    }
                }
            );
            if (result.data.body.message === "Goal exists") {
                setLoading(false);
                setUserGoal(JSON.parse(result.data.body.goalStatus));
                setTransactionsTotal(
                    JSON.parse(result.data.body.transactionsTotal)
                );
                setGoalStatus(result.data.body.message);
                setGoalExists(true);

                let goal = JSON.parse(result.data.body.goalStatus)

                let paid = JSON.parse(result.data.body.transactionsTotal)

                let itemPrice = goal.target.amount.amount + goal.target.tax.amount

                let percentage = Math.floor((paid / itemPrice) * 100)

                if (percentage >= 100 || itemPrice - paid < 500) {
                    setCanChipIn(false)
                }
            } else {
                console.log(result.data.body.message);
                setGoalExists(false);
            }
        }
        getGoal();

    }, []);

    function goalContent() {
        return goalExists ? (
            
            <GoalContainer userGoal={userGoal} className="standard-mobile-view">
                {/* <GoalOwner id="ownersName">
                    <span>{goalOwner}'s Reel</span>
                </GoalOwner>
                <GoalImage userGoal={userGoal}></GoalImage> */}
                <Progress
                    userGoal={userGoal}
                    itemPrice={itemPrice}
                    goalOwner={goalOwner}
                    payment={payment}
                    transactionsTotal={transactionsTotal}
                    loading={loading}
                ></Progress>
                {canChipIn &&
                    <PaymentForm
                        itemPrice={itemPrice}
                        goalOwner={goalOwner}
                        userGoal={userGoal}
                        goalId={goalId}
                        userId={userId}
                        userName={firstName}
                    />
                }
                {/* {canChipIn &&
                    <Link href={process.env.REACT_APP_REDIRECT_LINK} target="_blank">
                        Start Your Own Reel
                    </Link>
                } */}
                {!canChipIn && (
                    <PreventChipIn>
                        <PreventContent>
                            <p>
                                This Reel has been fully funded
                                <br></br> and is not accepting any more Chip ins.
                            </p>
                            <p>
                                What about you?<br></br> Start saving for what you love today!
                            </p>
                            <a href={process.env.REACT_APP_REDIRECT_LINK + utmParams} rel="noopener noreferrer" target="_blank">
                                <LinkButton>GO TO JOINREEL.COM</LinkButton>
                            </a>
                        </PreventContent>
                    </PreventChipIn>
                )}
            </GoalContainer>
        ) : (
                <ErrorPage goalStatus={goalStatus}>
                    <ErrorContainer>
                         <ErrorImg
                            type="image"
                            src={ErrorIcon}
                        ></ErrorImg>
                        <ErrorTitle>OOPS</ErrorTitle>
                        <ErrorText>This page does not exist</ErrorText>
                        <a href={process.env.REACT_APP_REDIRECT_LINK + utmParams} rel="noopener noreferrer" target="_blank">
                            <LinkButton>GO TO JOINREEL.COM</LinkButton>
                        </a>
                    </ErrorContainer>
                </ErrorPage>
            );
    }

    return goalContent();
};

export default Goal;

const ErrorPage = styled.div`
    display: flex;
    align-items: middle;
    height: 80vh;
    align-items: center;
`;

const GoalContainer = styled.div`
    width: 100%;
    max-width: 80%;
    margin: 50px auto 20px auto;
    display: flex;
    justify-content: center;
    visibility: ${props =>
        props.userGoal !== undefined ? "visible" : "hidden"};
`;

const GoalOwner = styled.h1`
    width: 100%;
    font-family: proxima-nova !important;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: 0.2px;
    margin: 0 auto;
    & > span {
        display: block;
        margin: 15px 0px 15px 14px;
    }

    @media ${device.tablet} {
        & > span {
            display: block;
            margin: 15px 0px 15px 0px;
        }
    }
`;

const GoalImage = styled.div`
    background-color: white;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    background-image: url(${props =>
        props.userGoal !== undefined
            ? props.userGoal.product.pictures.default.large
            : ""});
    margin: 5px 0;
    height: 374px;

    @media ${device.tablet} {
        width: 500px;
    }
`;

const Link = styled.a`
    display: block;
    width: 50%;
    margin: 20px auto 20px auto;
    font-size: 0.7em;
    line-height: 1.17;
    letter-spacing: -0.15px;
    text-align: center;
    font-family: Proxima-Nova;
    cursor: pointer; 
    color: #2c0066;
`;

const PreventChipIn = styled.div`
    & p {
        // width: 60%;
        // text-align: center;
        // font-size: 1em;
        // margin: 10px auto;
        // font-weight: 400;
        // font-family: Proxima-Nova;

        text-align: center;
        margin: 10px auto;
        font-family: 'Gotham-Book';
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.55;
        letter-spacing: -0.37px;
        color: #000000;
    }

    & a {
        text-decoration: none;
    }
`;

const PreventContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin-top: 80px;

    & p:nth-child(2){
        margin-top: 380px;
      }
    

      @media only screen and (min-width: 769px){
        margin-top: -20px;  
        p:nth-child(2){
            margin-top: 0px;
        }

        & p{
            font-size: 20px;
        }
    }

    @media only screen and (max-width: 346px){
        p:nth-child(2){
            margin-top: 300px;;
        }
    }
`;

const ErrorContainer = styled.div`
    width: 100%;

    text-align: center;
    & a {
        text-decoration: none;
    }
`;

const ErrorTitle = styled.h1`
    width: 100%;
    text-align: center;
    font-weight: 600;
    display: inline-block;
    position: relative;
    z-index: 1;
    margin-bottom: 0px;
    font-family: 'HaboroContrast-NorBoo';
    font-size: 43.2px;
    margin-top: 10px;

    &::after{
        position: absolute;
        content: '';
        bottom: 10px;
        width: 100px;
        height: 13px;
        background-color: #f39a7c;
        z-index: -1;
        left: 50%;
        transform: translate(-50%, 0px);
    }
`;
const ErrorText = styled.h1`
    width: 100%;
    text-align: center;
    font-size: 0.9em;
    margin: 10px 0;
    font-weight: 200;
`;

const LinkButton = styled.button`
    // color: white;
    // height: 40px;
    // background-color: #2c0066;
    // display: block;
    // border: none;
    // width: 260px;
    // font-family: proxima-nova;
    // font-weight: 400;
    // margin: 60px auto;
    // cursor: pointer;

    width: 300px;
    height: 46px;
    display: block;
    margin: 15px auto 10px auto;
    cursor: pointer;
    margin-top: 30px;
    border: none;
    padding: 0;
    text-transform: uppercase;
    background-color: #501639;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.92px;
    text-align: center;
    color: #ffffff;
    font-family: 'Gotham-Medium';

    & :focus {
        outline: none;
    }
`;


const ErrorImg = styled.img`
    display: block;
    margin: 0 auto;
`;