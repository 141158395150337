const progressReducer = (state, action) => {
    switch(action.type) {
        case 'USER_PERCENTAGE':
            return { ...state, userProgress:{
                ...state.userProgress
            , percentage: action.percentage }
            }
        case 'USER_PAID' :
            return { ...state, userProgress:{
                ...state.userProgress
            , paid: action.paid }
            }
        case 'WITH_CHIP_IN_PERCENTAGE':
            return { ...state, withChipInProgress:{
                ...state.withChipInProgress
            , percentage: action.percentage }
            }
        case 'WITH_CHIP_IN_PAID' :
            return { ...state, withChipInProgress:{
                ...state.withChipInProgress
            , paid: action.paid }
            }
        default:
            return state
    }
}

export default progressReducer